<template>
    <div class="advertising-funnel">
        <div
            v-if="salesDataExists"
            class="with-sales">
            <svg
                height="1750"
                viewBox="0 0 2811 1750"
                width="2811"
                xmlns="http://www.w3.org/2000/svg">
                <g
                    fill="none"
                    fill-rule="evenodd">
                    <!-- FUNNEL BACKGROUND -->
                    <!-- Main Color -->
                    <path
                        d="m2430.36 978 188.875-207h-666.87767l-183.88433 207zm-181.42 493.766h-664.467v274.467h664.467zm179.435-207.766h-659.33135l-183.22865 204h663.989zm1.565-282.234h-661.467v278.467h661.467zm191.28721-489h-669.36621v274.467h669.36621zm183.48379-210.766h-665.711l-186.69559 207h670.01659zm2.229-278.233h-664.467v274.466h664.467zm-2623.512 767.718-.428.000876v-.484876-.001-282h.200068l-183.200068-207v-282h2810.70621v282h-.66521l-185.041 210.01v278.99h-.353l-190.941 209.266v283.497l.10968.09578-.10968.12422.00021.017h-.01521l-180.985 206.756.00021 279.244h-1699.70621l-.001-278h-3.802306l-183.322814-208v-286h.293786z"
                        fill="#03a2ea"
                        fill-rule="nonzero" />
                    <!-- Darker Color -->
                    <path
                        d="m1769.04365 1264-186.82068 208h-1033.026276l-183.322814-208zm183.31368-493-183.88433 207h-1402.305334l-183.167666-207zm186.64267-489-186.69559 207h-1769.104342l-183.200068-207z"
                        fill="#000"
                        fill-opacity=".200216"
                        fill-rule="nonzero" />
                    <!-- FUNNEL TEXT -->
                    <text
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="120.527307"
                        font-weight="500">
                        <tspan
                            x="1165"
                            y="188">{{ metrics.impressions.formatted }}</tspan>
                    </text>
                    <g transform="translate(2259.118644 71.563089)">
                        <text
                            fill="#00a2ea"
                            font-family="OpenSans-Semibold, Open Sans"
                            font-size="79"
                            font-weight="500">
                            <tspan
                                text-anchor="middle"
                                x="170"
                                y="141">{{ metrics.spend.formatted }}</tspan>
                        </text>
                        <text
                            fill="#4a4a4a"
                            font-family="OpenSans-Regular, Open Sans"
                            font-size="41.431262">
                            <tspan
                                x="104.795941"
                                y="44">SPEND</tspan>
                        </text>
                    </g>
                    <g
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-weight="500">
                        <text font-size="75.329567">
                            <tspan
                                x="1165"
                                y="416">{{ metrics.ctr.formatted }}</tspan>
                        </text>
                        <text font-size="45.19774">
                            <tspan
                                x="580"
                                y="404">CLICKTHROUGH RATE</tspan>
                        </text>
                        <g transform="translate(325 64)">
                            <text font-size="75">
                                <tspan
                                    x="250"
                                    y="105">IMPRESSIONS</tspan>
                            </text>
                        </g>
                        <text font-size="75.329567">
                            <tspan
                                x="1165"
                                y="906">{{ metrics.lead_to_click_rate.formatted }}</tspan>
                        </text>
                        <text font-size="75.329567">
                            <tspan
                                x="1165"
                                y="1396">{{ metrics.offline_purchase_28d_rate.formatted }}</tspan>
                        </text>
                        <text font-size="45.19774">
                            <tspan
                                x="645.733522"
                                y="893">CONVERSION RATE</tspan>
                        </text>
                        <text font-size="45.19774">
                            <tspan
                                x="646.217514"
                                y="1383">CONVERSION RATE</tspan>
                        </text>
                        <text font-size="120.527307">
                            <tspan
                                x="1165"
                                y="679">{{ metrics.clicks.formatted }}</tspan>
                        </text>
                        <text font-size="120.527307">
                            <tspan
                                x="1165"
                                y="1168">{{ metrics.leads.formatted }}</tspan>
                        </text>
                        <text font-size="120.527307">
                            <tspan
                                x="1165"
                                y="1657">{{ metrics.offline_purchase_28d_total.formatted }}</tspan>
                        </text>
                        <g transform="translate(496 537)">
                            <text font-size="75">
                                <tspan
                                    x="300"
                                    y="120">CLICKS</tspan>
                            </text>
                        </g>
                    </g>
                    <g transform="translate(2013.896422 534.839925)">
                        <text
                            fill="#00a2ea"
                            font-family="OpenSans-Semibold, Open Sans"
                            font-size="79.096045"
                            font-weight="500">
                            <tspan
                                x="144.074925"
                                y="172">{{ metrics.cost_per_click.formatted }}</tspan>
                        </text>
                        <text
                            fill="#4a4a4a"
                            font-family="OpenSans-Regular, Open Sans"
                            font-size="41.431262">
                            <tspan
                                x="120"
                                y="90">COST / CLICK</tspan>
                        </text>
                    </g>
                    <text
                        fill="#00a2ea"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="79.096045"
                        font-weight="500">
                        <tspan
                            x="1940.04863"
                            y="1176.78154">{{ metrics.cost_per_lead.formatted }}</tspan>
                    </text>
                    <text
                        fill="#4a4a4a"
                        font-family="OpenSans-Regular, Open Sans"
                        font-size="41.431262">
                        <tspan
                            x="1920.04727"
                            y="1079.78154">COST / LEAD</tspan>
                    </text>
                    <g transform="translate(1639.608286 1529.190207)">
                        <text
                            fill="#00a2ea"
                            font-family="OpenSans-Semibold, Open Sans"
                            font-size="79.096045"
                            font-weight="500">
                            <tspan
                                x="106.297493"
                                y="149">{{ metrics.cost_per_offline_purchase_28d.formatted }}</tspan>
                        </text>
                        <text
                            fill="#4a4a4a"
                            font-family="OpenSans-Regular, Open Sans"
                            font-size="41.431262">
                            <tspan
                                x="122.555835"
                                y="44">COST / SALE</tspan>
                        </text>
                    </g>
                    <text
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="75"
                        font-weight="500">
                        <tspan
                            x="835"
                            y="1150">LEADS</tspan>
                    </text>
                    <text
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="75"
                        font-weight="500">
                        <tspan
                            x="844.437012"
                            y="1639">SALES</tspan>
                    </text>
                </g>
            </svg>
        </div>
        <div
            v-else
            class="no-sales">
            <svg
                height="1264"
                viewBox="0 0 2811 1264"
                width="2811"
                xmlns="http://www.w3.org/2000/svg">
                <g
                    fill="none"
                    fill-rule="evenodd">
                    <!-- FUNNEL BACKGROUND -->
                    <!-- Main Color -->
                    <path
                        d="m2430.36 978 188.875-207h-666.87767l-183.88433 207zm-.42 3.766h-661.467v278.467h661.467zm191.28721-489h-669.36621v274.467h669.36621zm183.48379-210.766h-665.711l-186.69559 207h670.01659zm2.229-278.233h-664.467v274.466h664.467zm-2623.512 767.718-.428.000876v-.484876-.001-282h.200068l-183.200068-207v-282h2810.70621v282h-.66521l-185.041 210.01v278.99h-.353l-190.941 209.266.00021 283.734h-2067.83233v-286h.293786z"
                        fill="#03a2ea"
                        fill-rule="nonzero" />
                    <!-- Darker Color -->
                    <path
                        d="m0 282h2139l-186.69559 207h-1769.104342z"
                        fill="#000"
                        fill-opacity=".200216"
                        fill-rule="nonzero" />
                    <path
                        d="m183 771h1769.35733l-183.88433 207h-1402.305334z"
                        fill="#000"
                        fill-opacity=".200216"
                        fill-rule="nonzero" />

                    <!-- FUNNEL TEXT -->
                    <text
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="120.527307"
                        font-weight="500">
                        <tspan
                            x="1165"
                            y="188">{{ metrics.reach.formatted }}</tspan>
                    </text>
                    <g transform="translate(2259.118644 71.563089)">
                        <text
                            fill="#00a2ea"
                            font-family="OpenSans-Semibold, Open Sans"
                            font-size="79.096045"
                            font-weight="500">
                            <tspan
                                x="47.941678"
                                y="141">{{ metrics.spend.formatted }}</tspan>
                        </text>
                        <text
                            fill="#4a4a4a"
                            font-family="OpenSans-Regular, Open Sans"
                            font-size="41.431262">
                            <tspan
                                x="104.795941"
                                y="44">SPEND</tspan>
                        </text>
                    </g>
                    <g
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-weight="500">
                        <text font-size="75.329567">
                            <tspan
                                x="1165"
                                y="416">{{ metrics.ctr.formatted }}</tspan>
                        </text>
                        <text font-size="45.19774">
                            <tspan
                                x="351.034958"
                                y="404">UNIQUE OUTBOUND CLICKS CTR</tspan>
                        </text>
                        <g transform="translate(325 64)">
                            <text font-size="75">
                                <tspan
                                    x="320.437988"
                                    y="80">PROSPECTS</tspan>
                            </text>
                            <text font-size="48.964219">
                                <tspan
                                    x="555.73233"
                                    y="140">(Reach)</tspan>
                            </text>
                        </g>
                        <text font-size="75.329567">
                            <tspan
                                x="1165"
                                y="906">{{ metrics.lead_to_click_rate.formatted }}</tspan>
                        </text>
                        <text font-size="45.19774">
                            <tspan
                                x="645.733522"
                                y="893">CONVERSION RATE</tspan>
                        </text>
                        <text font-size="120.527307">
                            <tspan
                                x="1165"
                                y="679">{{ metrics.clicks.formatted }}</tspan>
                        </text>
                        <text font-size="120.527307">
                            <tspan
                                x="1165"
                                y="1168">{{ metrics.leads.formatted }}</tspan>
                        </text>
                        <g transform="translate(496 537)">
                            <text font-size="41.431262">
                                <tspan
                                    x="52.169536"
                                    y="150.862524">(Unique Outbound Clicks)</tspan>
                            </text>
                            <text font-size="75">
                                <tspan
                                    x="1.260642"
                                    y="80">UNIQUE CLICKS</tspan>
                            </text>
                        </g>
                    </g>
                    <g transform="translate(2013.896422 534.839925)">
                        <text
                            fill="#00a2ea"
                            font-family="OpenSans-Semibold, Open Sans"
                            font-size="79.096045"
                            font-weight="500">
                            <tspan
                                x="144.074925"
                                y="172">{{ metrics.cost_per_unique_click.formatted }}</tspan>
                        </text>
                        <text
                            fill="#4a4a4a"
                            font-family="OpenSans-Regular, Open Sans"
                            font-size="41.431262">
                            <tspan
                                x="150"
                                y="38.783928">COST PER</tspan>
                            <tspan
                                x="100"
                                y="83.981668">UNIQUE CLICK</tspan>
                        </text>
                    </g>
                    <text
                        fill="#00a2ea"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="79.096045"
                        font-weight="500">
                        <tspan
                            x="1940.04863"
                            y="1176.78154">{{ metrics.cost_per_lead.formatted }}</tspan>
                    </text>
                    <text
                        fill="#4a4a4a"
                        font-family="OpenSans-Regular, Open Sans"
                        font-size="41.431262">
                        <tspan
                            x="1920.04727"
                            y="1079.78154">COST / LEAD</tspan>
                    </text>
                    <text
                        fill="#fff"
                        font-family="OpenSans-Semibold, Open Sans"
                        font-size="75"
                        font-weight="500">
                        <tspan
                            x="864.31543"
                            y="1150">LEADS</tspan>
                    </text>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'AdvertisingFunnel',
    computed: {
        ...mapGetters('metrics/facebook/ads', {
            metrics: 'metricsTotalsFormatted'
        }),
        salesDataExists() {
            return !!this.metrics?.offline_purchase_28d_total ?? false;
        }
    },
};
</script>

<style lang="scss" scoped>
.with-sales {
  margin-top: 30px;
}
.no-sales {
  margin-top: 120px;
}
svg {
  height: auto;
  width: 100%;
}
</style>
